import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/min/locales';
import Validator from 'js/utils/validator'
export default function setLocalization(localization){

	i18n.use(initReactI18next) // passes i18n down to react-i18next
		.init({
			resources: {
				[localization.locale]: {
					translation: localization.translations
				}
			},
			lng: localization.locale,
			returnObjects: true,
			debug: true,
			interpolation: {
				escapeValue: false, // react already safes from xss
			}
		});

	try {
		moment.locale(localization.locale);
	} catch (e){
		console.error(e);
	}

	let customMessages =  i18n.t("validation.custom");

	let defaultMessages = Validator.getMessages(i18n.language);

	let messages = {
		...defaultMessages,
		...customMessages,
	};
	Validator.useLang(i18n.language)
	Validator.setMessages(i18n.language, messages);


};
