import React from "react";
import {Switch} from "react-router-dom";
import Login from "./js/Pages/Auth/Login";
import ForgotPassword from "./js/Pages/Auth/ForgotPassword";
import ResetPassword from "./js/Pages/Auth/ResetPassword";
import SetPassword from "./js/Pages/Auth/SetPassword";
import CustomRoute from "./js/components/Router/CustomRoute";

const Dashboard = React.lazy(() => import("./js/Pages/Dashboard"));
const NotFound = React.lazy(() => import("./js/Pages/NotFound"));

function Routes(props) {
	return (
		<Switch>
			<CustomRoute exact={true} path='/' component={Dashboard} isPrivate {...props}/>

			<CustomRoute exact={true} path='/login' component={Login}  {...props}/>
			<CustomRoute exact={true} path='/forgot-password' component={ForgotPassword}  {...props}/>
			<CustomRoute exact={true} path='/reset-password/:token' component={ResetPassword}  {...props}/>
			<CustomRoute exact={true} path='/set-password/:token' component={SetPassword}  {...props}/>
			<CustomRoute component={NotFound} isPrivate/>
		</Switch>
	);
}

export default Routes;
