import React from 'react';
import Logo from "../../../assets/media/logos/logo-meelog-white.svg";
import { Link, useHistory} from "react-router-dom";
import CustomFormik from 'js/components/Form/CustomFormik';
import { Form, Field, ErrorMessage } from 'formik';
import { useToasts } from 'react-toast-notifications';
import {useTranslation} from "react-i18next";

export default function SetPassword(props) {
	const { t } = useTranslation();
	const { addToast } = useToasts();
	const history = useHistory();

	return (
		<>
			<div className="logo-wrap">
				<img src={Logo} alt="MyDiary logo"/>
			</div>
			<div className="auth-block block">
				<div className="auth-block-header">
					<h1>{t('login.app.set-password.set-your-password')}</h1>
				</div>
				<CustomFormik
					url='user/setPassword'
					method='post'
					initialValues={{
                        login: '',
                        password: '', 
                        password_confirmation: '', 
                        token: props.match.params.token}}
					rules={{
                        login: 'required',
                        password: 'required|confirmed',
                        password_confirmation: 'required'
					}}
					attributeNames={{
						login: t('login.label.login'),
						password: t('login.label.password'),
						password_confirmation: t('login.label.confirm-password')
					}}
					onSuccess={(response) => {
						addToast(t('passwords.password_changed'), {
							appearance: "success",
							autoDismiss: true
						 });
						history.push("/login");
					}}
					>
					{({ isSubmitting }) => (
						<Form>
                            <div className="input-error-message text-danger">
								<ErrorMessage name="token"  />
							</div>
							<div className='input-text'>
								<Field type="text" name="login" placeholder={t('login.label.login')} />
								<div className="input-error-message text-danger">
									<ErrorMessage name="login"  />
								</div>
							</div>
                            <div className='input-text'>
                                <Field type="password" name="password" placeholder={t('login.label.password')} />
                                <div className="input-error-message text-danger">
                                    <ErrorMessage name="password"  />
                                </div>
							</div>
                            <div className='input-text'>
                                <Field type="password" name="password_confirmation" placeholder={t('login.label.confirm-password')} />
                                <div className="input-error-message text-danger">
                                    <ErrorMessage name="password_confirmation"  />
                                </div>
							</div>
							<div className="form-group">
								<button className="btn-secondary"  type="submit" disabled={isSubmitting}>
									{t('common.action.continue')}
								</button>
							</div>
						</Form>
					)}
				</CustomFormik>
				<div className="auth-block-footer">
                    <Link to="/login">{t('login.action.back-to-login')}</Link>
                </div>
			</div>
		</>
	);
	
}