import React from 'react';

// ICONS GENERAL
export const IconDelete = ({className}) => {
    return <svg className={`svg-inline ${className ? className : ''}`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path d="M5,2 L5,0 L15,0 L15,2 L20,2 L20,4 L18,4 L18,19 C18,19.5522847 17.5522847,20 17,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,4 L0,4 L0,2 L5,2 Z M4,4 L4,18 L16,18 L16,4 L4,4 Z M7,7 L9,7 L9,15 L7,15 L7,7 Z M11,7 L13,7 L13,15 L11,15 L11,7 Z"></path>
		</svg>;
};

export const IconEdit = ({className}) => {
    return <svg className={`svg-inline ${className ? className : ''}`} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.91609357,3.83501058 L14.1649894,8.08490806 L4.24889585,18 L0,18 L0,13.7501025 L9.91609357,3.83400895 L9.91609357,3.83501058 Z M11.3323922,2.41871196 L13.4568401,0.293262411 C13.8479748,-0.0977541371 14.4820041,-0.0977541371 14.8731387,0.293262411 L17.7067376,3.12686127 C18.0977541,3.51799592 18.0977541,4.15202523 17.7067376,4.54315989 L15.581288,6.66760781 L11.3323922,2.41871196 L11.3323922,2.41871196 Z"></path>
		</svg>;
};

export const IconArrow = ({className}) => {
    return <svg className={`svg-inline ${className ? className : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 668 468.75">
            <path d="M363 12l293 292c16,17 16,43 0,59 -16,16 -43,16 -59,0l-263 -263 -263 263c-17,16 -43,16 -59,0 -16,-16 -16,-42 0,-59l292 -292c17,-16 43,-16 59,0z"></path>
        </svg>;
};

// Loader
export const IconLoader = ({className}) => {
    return <svg className={`svg-inline ${className ? className : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <circle cx="50" cy="50" fill="none" stroke="#ffffff" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(12.2954 50 50)">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
    </svg>;
};