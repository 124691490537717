
export default {
   
    set: (token) => {
        localStorage.setItem("auth_token", token);
    },

    get: () => {
        return localStorage.getItem("auth_token");
    },

    clear: () => {
        localStorage.removeItem("auth_token");
    }
    
}
