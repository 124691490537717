import React, {useEffect, useState} from 'react';

import {useTranslation} from "react-i18next";
import ProfilePlaceholder from 'assets/media/various/profile-placeholder.svg';
import Sidebar from "./Sidebar";
import DiaryOrange from 'assets/media/diaries/journal-style-01.svg';
import DiaryGreen from 'assets/media/diaries/journal-style-02.svg';
import DiaryPurple from 'assets/media/diaries/journal-style-03.svg';
import DiaryTurquoise from 'assets/media/diaries/journal-style-04.svg';
import DiaryYellow from 'assets/media/diaries/journal-style-05.svg';
import DiaryBrown from 'assets/media/diaries/journal-style-06.svg';

const Header = (props) => {
	const { t } = useTranslation();
	const [showMenu, setShowMenu] = useState(false);
	const user = props.globalState.user
	const currentDiaryColor = !!user ? props.globalState.selectedDiary?.color : "E5572A";
	
	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	useEffect(() => {
		if (showMenu) {
			document.body.classList.add('menu-open');
		} else {
			document.body.classList.remove('menu-open');
		}
	}, [showMenu]);



	const colorpickerValues = {
		orange: "E5572A",
		green: "A8BB49",
		purple: "C078CE",
		turquoise: "53D9F7",
		yellow: "D3AB03",
		brown: "5A2314"
	};

	const getDiaryColor = () => {
		switch(currentDiaryColor) {
			case colorpickerValues.orange:
				return DiaryOrange;
			case colorpickerValues.green:
				return DiaryGreen;
			case colorpickerValues.purple:
				return DiaryPurple;
			case colorpickerValues.turquoise:
				return DiaryTurquoise;
			case colorpickerValues.yellow:
				return DiaryYellow;
			case colorpickerValues.brown:
				return DiaryBrown;
			default:
				throw new Error("Diary has no color!");
		}
	}


	if (!user) {
		return null;
	}
	
	return (
		<>
			<header id="header">
				<div className="header-wrap">
					<div className="inner-wrap">
						<div className="img-user">
							<img src={user?.profilePicture || ProfilePlaceholder} alt={user.firstName}/>
						</div>

						<h2>{t('common.title.welcome-name', {name: user?.firstName ?? ''})}</h2>
						<h4>{t('common.app.how-is-your-day')}</h4>
					</div>

					{!!props.globalState.selectedDiary &&
						<div className="journal-wrap">
							<div className="journal-wrap-img">
								<img src={getDiaryColor()} alt="Journal illustration"/>
							</div>

							<p>{t("diary.label.the-diary-of-name", {name: props.globalState.selectedDiary.name})}</p>
						</div>
					}
					
					<button className="settings-menu" onClick={toggleMenu}>
						<span className="settings-menu-icon"/>
					</button>
				</div>
			</header>

			<Sidebar 
				globalState={props.globalState}
				close={() => {
				toggleMenu()
					
			}} />
		</>
	);
};

export default Header;
