import React from 'react';
import DiaryOrange from 'assets/media/diaries/journal-style-01.svg';
import DiaryGreen from 'assets/media/diaries/journal-style-02.svg';
import DiaryPurple from 'assets/media/diaries/journal-style-03.svg';
import DiaryTurquoise from 'assets/media/diaries/journal-style-04.svg';
import DiaryYellow from 'assets/media/diaries/journal-style-05.svg';
import DiaryBrown from 'assets/media/diaries/journal-style-06.svg';
import JournalImage from 'assets/media/user-avatar/avatar-01.png';

const JournalCard = ({diary}) => {
	const colorpickerValues = {
		orange: "E5572A",
		green: "A8BB49",
		purple: "C078CE",
		turquoise: "53D9F7",
		yellow: "D3AB03",
		brown: "5A2314"
	};

	const getDiaryColor = () => {
		switch(diary.color) {
			case colorpickerValues.orange:
				return DiaryOrange;
			case colorpickerValues.green:
				return DiaryGreen;
			case colorpickerValues.purple:
				return DiaryPurple;
			case colorpickerValues.turquoise:
				return DiaryTurquoise;
			case colorpickerValues.yellow:
				return DiaryYellow;
			case colorpickerValues.brown:
				return DiaryBrown;
			default:
				throw new Error("Diary has no color!");
		}
	}

	return (
		<>
			<div className="journal-card-img">
				<div className="journal-card-img-bg">
					<img src={getDiaryColor()} alt="Journal illustration" />
				</div>
				<div className="journal-card-img-user">
					<img src={!!diary.avatar ? diary.avatar : JournalImage} alt="Journal user thumb" />
				</div>
			</div>
			<span>{diary.name}</span>
		</>
	);
};

export default JournalCard;
