import React from 'react';
import Logo from "../../../assets/media/logos/logo-meelog-white.svg";
import { Link, useHistory} from "react-router-dom";
import CustomFormik from 'js/components/Form/CustomFormik';
import { Form, Field, ErrorMessage } from 'formik';
import { useToasts } from 'react-toast-notifications';
import {useTranslation} from "react-i18next";

export default function ResetPassword(props) {
	const { t } = useTranslation();
	const { addToast } = useToasts();
	const history = useHistory();

	return (
		<>
			<div className="logo-wrap">
				<img src={Logo} alt="MyDiary logo"/>
			</div>
			<div className="auth-block block">
				<div className="auth-block-header">
					<h1>{t('login.app.reset-password.reset-your-password')}</h1>
				</div>
				<CustomFormik
					validateOnBlur={false}
					validateOnChange={false}
					url='user/resetPassword'
					method='post'
					initialValues={{ password: '', password_confirmation: '', token: props.match.params.token}}
					rules={{ password: 'required|confirmed|min:8|strong_password', password_confirmation: 'required'}}
					attributeNames={{
						password: t('login.label.password'),
						password_confirmation: t('login.label.confirm-password')
					}}
					onSuccess={(response) => {
						addToast(t("passwords.password_successfully_reset"), {
							appearance: "success",
							autoDismiss: true
						 })
						history.push("/login")
					}}
					>
					{({ isSubmitting }) => (
						<Form>
							<div className="input-error-message text-danger">
								<ErrorMessage name="token"  />
							</div>
							<div className='input-text'>
								<Field type="password" name="password" placeholder={t('login.label.password')} />
								<div className="input-error-message text-danger">
									<ErrorMessage name="password"  />
								</div>
							</div>
                            <div className='input-text'>
								<Field type="password" name="password_confirmation" placeholder={t('login.label.confirm-password')} />
								<div className="input-error-message text-danger">
									<ErrorMessage name="password_confirmation"  />
								</div>
							</div>
							<div className="form-group">
								<button className="btn-secondary"  type="submit" disabled={isSubmitting}>
									{t('common.action.continue')}
								</button>
							</div>
						</Form>
					)}
				</CustomFormik>
				<div className="auth-block-footer">
						<Link to="/login">{t('login.action.back-to-login')}</Link>
					</div>
			</div>
		</>
	);
	
}

