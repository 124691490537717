import React, {useEffect, useState} from "react";
import { withRouter } from 'react-router-dom';
import Routes from "../routes";
import Header from "./Shared/Header";
import auth from 'js/utils/auth';
import auth_token from "js/utils/auth_token";
import Footer from "./Shared/Footer";
import api from 'js/utils/api';
import Diary from 'js/Models/Diary';
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import {apiUrl} from "js/utils/api_url"
import setLocalization from 'js/utils/i18n';


function App(props) {
   
    useEffect(() => {
        // Detect device orientation
        onOrientationChange();
        
        if ((window.screen.orientation) && ('onchange' in window.screen.orientation)) {
            window.screen.orientation.addEventListener('change', onOrientationChange)
        } else if ('onorientationchange' in window) {
            window.addEventListener('orientationchange', onOrientationChange)
        } else {
            console.warn('No orientationchange events')
        }
        
        window.addEventListener("resize", onOrientationChange);
    }, []);                

    const onOrientationChange  = () => {
        let orientation = 'portrait';
        let type = 'primary';
        let angle = 0;

        if (window.orientation){
            angle = window.orientation;
            orientation = Math.abs(angle) === 90 ? 'landscape' : 'portrait';
        }

        if (window.screen.orientation){
            [orientation, type] = window.screen.orientation.type.split('-');
            angle = window.screen.orientation;
        }

        if (orientation === 'portrait') {
            document.documentElement.classList.add('portrait');
            document.documentElement.classList.remove('landscape');
        } else {
            document.documentElement.classList.add('landscape');
            document.documentElement.classList.remove('portrait');
        }
    };

    // Detect scroll position
    useScrollPosition(({ prevPos, currPos }) => {  
        const isHide = currPos.y > prevPos.y;
        
        if (!isHide) {
            document.body.classList.add('scrolled');
        } else {
            document.body.classList.remove('scrolled');
        }
    }, [], false, false, 100 );
    
    
    const token = auth_token.get();
    const isAuth = auth.check();
	const [user, setUser] = useState(null);
    const [selectedDiaryId, setSelectedDiaryId] = useState(localStorage.getItem("selected_diary_id"));
    const [diaries, setDiaries] = useState([]);
    const [locale, setLocale] = useState([]);
    const [isAddEvent, setIsAddEvent] = useState(true);
    const [isLoading, setLoading] = useState(true);

    if (isAuth){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + auth_token.get();
    }
    if (isAuth && user == null){
        if (auth.user()){
            setUser(auth.user());
        } else {
            auth.logout();
        }
        
    }
   
    let globalState = {
        user,
        diaries,
        locale,
        selectedDiary: diaries.find((diary) => (parseInt(diary.id) === parseInt(selectedDiaryId))) ?? null,
        isLoading,
        setSelectedDiaryId,
        setUser,
        setLoading,
        setLocale
    }
    
    if (!globalState.selectedDiary && diaries.length > 0){
        setSelectedDiaryId(diaries[0].id);
    }

    useEffect(() => {
        api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }, [token])

    useEffect(() => {
        let locale = getLocale(user);
        api.defaults.headers.common['Accept-Language'] = locale;
        setLocale(locale);
    }, [user]);

    useEffect(() => {
        if (!locale){
            return;
        }
        api.get(apiUrl(`languages/${locale}/translations`))
            .then(result => {
                let resources = {
                    [locale]: {
                        translation: result.data
                    }
                }
                resources[locale] = result.data
                setLocalization({
                    locale,
                    translations: result.data.data
                })
                setLoading(false)
            })
    }, [locale]);


    useEffect(() => {
        if (!user){
            return;
        }

		api.get("user/diaries")
			.then(result => {
				if (result.data) {
                    setDiaries(result.data.data.map((data) => {
                        return new Diary(data);
                    }))
				}
        });
        
      
	}, [user]);


	useEffect(() => {
        if (!!isAuth) {
            document.body.classList.remove('public');
		} else {
            document.body.classList.add('public');
		}
	}, [isAuth]);
    
	useEffect(() => {
        localStorage.setItem("selected_diary_id", selectedDiaryId);
	}, [selectedDiaryId])
    
    if (isLoading){
        return "";
    }

	return (
		<>
			<Header globalState={globalState} {...props}/>

			<React.Suspense fallback={<div/>}>
				<Routes isAddEvent={isAddEvent} setIsAddEvent={setIsAddEvent} globalState={globalState}/>
			</React.Suspense>
            
            {
                !isAddEvent &&
			    <Footer globalState={globalState} {...props} />
            }
		</>
	);
}

function getLocale(user){
    let queryParams = new URLSearchParams(window.location.search);

    if (queryParams.has('language')){
        return queryParams.get('language');
    }
    if (user){
        return user.languageCode;
    }
    
    return 'en';
}

export default withRouter(App);
