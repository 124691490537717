export default class Diary {

	constructor(data) {
		this.data = data

		
		this.id = this.data.id;
		this.name = this.data.attributes.name;
		this.color = this.data.attributes.color;
	}
}
