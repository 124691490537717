import React  from 'react';
import { Formik } from 'formik';
import Validator from 'js/utils/validator';
import api from 'js/utils/api';
import { useToasts } from 'react-toast-notifications';
import {useTranslation} from "react-i18next";


export default function CustomFormik(props){
	const { t } = useTranslation();
	const { addToast } = useToasts();

	return (
		<Formik
			{...props}
			validate={(values) => {
				let validation = new Validator(values, props.rules ?? []);

				if (props.attributeNames){
					validation.setAttributeNames(props.attributeNames);
				}
				
				if (validation.passes()){
					return {};
				}

				let errors = {};

				for (let index in validation.errors.errors){
					let list = validation.errors.errors[index];

					errors[index] = list.join("\n");
				}
				return errors;
			}}

			
			onSubmit={(values, { setFieldValue, setSubmitting, setErrors }) => {
				setSubmitting(true);

				let handleSubmit = props.handleSubmit ?? ((values) => {
					return api[props.method](props.url, values);
				})
				handleSubmit(values)
					.then((result) => {
						setSubmitting(false);

						if (props.clear) {
							for (let field of props.clear){
								setFieldValue(field, '')
							}
						}

						// custom status returned by background sync
						if (result.status === 299){
							addToast(t("common.message.warning-background-save", 'original'), {
								appearance: "info",
								autoDismiss: true
							});
							if (props.onBackgroundSave()){
								props.onBackgroundSave(result)
								
							}
							return;
						}

						if (props.onSuccess) {
							props.onSuccess(result)
						}
					})
					.catch((errors) => {
						setSubmitting(false);
						if (!errors.response){
							addToast(t("common.message.error-could-not-reach-server", 'original'), {
								appearance: "error",
								autoDismiss: true
							})
							return;
							
						}
	
						if (errors.response.status === 422){
							setErrors(errors.response.data.errors)	
							return
						}

						if (errors.response.status === 404) {
							addToast(t("common.message.error-page-not-found", 'original'), {
								appearance: "error",
								autoDismiss: true
							});

							return;
						}

						if (props.onError) {
							return props.onError(errors);
						} else {
							console.log(errors);
							addToast(t("common.message.error-general", 'original'), {
								appearance: "error",
								autoDismiss: true
							})
						}
					})
			}}
		>

		</Formik>
	);
}
