import React from 'react';

const PublicLayout = ({children}) => {
	return (
		<main className="container-public">
			{children}
		</main>
	);
};

export default PublicLayout;
