import React from 'react';
import {Link, useHistory, withRouter} from "react-router-dom";
import auth from "js/utils/auth";
import Logo from "../../../assets/media/logos/logo-meelog-white.svg";
import { Form, Field, ErrorMessage } from 'formik';
import CustomFormik from 'js/components/Form/CustomFormik';
import { withToastManager } from 'react-toast-notifications';
import {useTranslation} from "react-i18next";

const Login = () => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<>
			<div className="logo-wrap">
				<img src={Logo} alt="MyDiary"/>
			</div>
			<div className="auth-block block">
				<div className="auth-block-header">
					<h1>{t("login.page.login.title-line-1")}
					<br />{t("login.page.login.title-line-2")}</h1>
				</div>
				<CustomFormik
					url='auth/login'
					method='post'
					initialValues={{ email: '', password: '', device_name: navigator.userAgent}}
					rules={{ email: 'required', password: 'required' }}
					attributeNames={{
						email: t('common.label.email'),
						password: t('login.label.password')
					}}
					handleSubmit={(values) => {
							return auth.login(values)
					}}
					onSuccess={(response) => {
						history.push("/")
					}}
					>
					{({ isSubmitting }) => (
						<Form>
							<div className='input-text'>
									<Field type="text" name="email" placeholder={t('common.label.email')} />
									<div className="input-error-message text-danger">
											<ErrorMessage name="email"  />
									</div>
							</div>
							<div className='input-text'>
									<Field type="password" name="password" placeholder={t('login.label.password')} />
									<div className="input-error-message text-danger">
											<ErrorMessage name="password"  />
									</div>
							</div>
							<div className="form-group">
									<button className="btn-secondary"  type="submit" disabled={isSubmitting}>
										{t('login.action.login')}
									</button>
							</div>
						</Form>
					)}
				</CustomFormik>
				<div className="auth-block-footer">
					<Link to="/forgot-password">{t('login.action.forgot-password')}</Link>
				</div>
			</div>
		</>
	);
};

export default withRouter(withToastManager(Login));
