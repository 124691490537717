import React from 'react';
import Logo from "../../../assets/media/logos/logo-meelog-white.svg";
import { Link, useHistory} from "react-router-dom";
import CustomFormik from 'js/components/Form/CustomFormik';
import { Form, Field, ErrorMessage } from 'formik';
import { useToasts } from 'react-toast-notifications';
import {useTranslation} from "react-i18next";

export default function ForgotPassword(props) {
	const { t } = useTranslation();
	const { addToast } = useToasts();
	const history = useHistory();

	return (
		<>
			<div className="logo-wrap">
				<img src={Logo} alt="MyDiary logo"/>
			</div>
			<div className="auth-block block">
				<div className="auth-block-header">
					<h1>{t('login.action.question-forgot-password')}</h1>
				</div>
				<CustomFormik
					url='forgotPassword'
					method='post'
					initialValues={{ email: ''}}
					rules={{ email: 'required|email'}}   
					attributeNames={{ email: t('common.label.email') }}                   
					onSuccess={(response) => {
						addToast(t("passwords.sent"), {
							appearance: "success",
							autoDismiss: true
						 });
						history.push("/login");
					}}
					>
					{({ isSubmitting }) => (
						<Form>
							<div className='input-text'>
								<Field type="text" name="email" placeholder={t('common.label.email')} />
								<div className="input-error-message text-danger">
									<ErrorMessage name="email"  />
								</div>
							</div>
							<div className="form-group">
								<button className="btn-secondary"  type="submit" disabled={isSubmitting}>
									{t('common.action.continue')}
								</button>
							</div>
						</Form>
					)}
				</CustomFormik>
				<div className="auth-block-footer">
						<Link to="/login">{t('login.action.back-to-login')}</Link>
					</div>
			</div>
		</>
	);
	
}

