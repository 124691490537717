import api from "js/utils/api";
import { apiUrl } from "js/utils/api_url"

export default class User {
	constructor(data) {
		this.data = data;
		this.diaries = [];

		this.id = this.data.id
		this.firstName = this.data.attributes.first_name || '';
		this.languageCode = this.data.attributes.language_code || 'en';
		this.profilePicture = this.data.attributes.profile_picture_url ;
	}

	refresh() {
		return new Promise((resolve, reject) => {
			api.get(apiUrl("user/"+this.id))
				.then((response) => {
					let user = response.data.data;
					localStorage.setItem("user", JSON.stringify(user));

					resolve(new User(user))
				})
				.catch((error) => reject(error))
		});

	}

}