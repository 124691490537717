import React from 'react';

const AuthLayout = ({props, children}) => {
	return (
			<main className="container-auth">
				{children}
			</main>
	);
};

export default AuthLayout;
