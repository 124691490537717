import React, {useEffect, useState} from 'react';
import { IconArrow } from '../components/Icons/Icons';
import JournalCard from "./Cards/JournalCard";
import {useTranslation} from "react-i18next";

const Footer = (props) => {
	const user = props.globalState.user
	const { t } = useTranslation();
	const [showFooterDiaries, setShowFooterDiaries] = useState(false);

	const toggleFooterDiaries = () => {
		setShowFooterDiaries(!showFooterDiaries);
	};

	useEffect(() => {
		const footer = document.getElementById('footer');

		if (footer) {
			if (showFooterDiaries) {
				footer.classList.add('journals-open');
				document.body.classList.add('no-scroll');
			} else {
				footer.classList.remove('journals-open');
				document.body.classList.remove('no-scroll');
			}
		}
	}, [showFooterDiaries]);

	const updateDiary = (id) => {
		toggleFooterDiaries();
		props.globalState.setSelectedDiaryId(id);
	};

	if (!user) {
		return null;
	}
	return (
		props.globalState.diaries.length > 1 &&
		<footer id="footer">
			<div className="journal-collection">
				<div className="journal-collection-wrapper">
					<div className="journal-collection-header" onClick={toggleFooterDiaries}>
						<h5>{t("diary.label.your-diaries")}</h5>
						<IconArrow/>
					</div>
					<div className="journal-collection-body">
						<ul className="journal-card-wrapper">
							{!!props.globalState.diaries && props.globalState.diaries.map(diary =>
								<li className={`journal-card ${ parseInt(props.globalState.selectedDiary?.id) === parseInt(diary.id) ? 'selected' : ''}`}
									onClick={() => updateDiary(diary.id)}
										key={`user-diaries-${diary.id}`}>
									<JournalCard diary={diary}/>
								</li>
							)}
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;