import React from 'react';
import { Redirect, Route } from "react-router-dom";
import PublicLayout from "../../Shared/PublicLayout";
import AuthLayout from "../../Shared/AuthLayout";
import  auth  from "js/utils/auth";

export default function RouteWrapper({
 component: Component,
 isPrivate,
 ...rest
}) {
	const isAuth = auth.check();

	if (isPrivate && (!isAuth)) {
		return <Redirect to="/login"/>;
	}

	if (!isPrivate && !!isAuth) {
		rest.globalState.setLoading(true)
		auth.logout().then(() => {
			rest.globalState.setUser(null)
			rest.globalState.setLoading(false)
		});
		return "";
	}

	const Layout = !!isAuth ? AuthLayout : PublicLayout;

	return (
		<Route
			{...rest}
			render={props => (
				<Layout>
					<Component {...props} {...rest}/>
				</Layout>
			)}
		/>
	);
}

RouteWrapper.defaultProps = {
	isPrivate: false,
};
