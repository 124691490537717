import axios from "axios";
import {apiUrl} from "js/utils/api_url";
import auth_token from "js/utils/auth_token";

let instance = axios.create({
    baseURL: apiUrl(),
    headers: {
        'Access-Control-Allow-Origin': '*',
    }
});

instance.interceptors.response.use(function (response) {
    return response;
    }, function (error) {
        if (error.response && error.response.status === 401){
            auth_token.clear()
            localStorage.clear('user');
            window.location.reload();
        }
    return Promise.reject(error);
    });



export default instance;
 

