import React from 'react';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import ReactDOM from 'react-dom';
import './sass/app.scss';
import App from './js/App';
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications';


Bugsnag.start({
    releaseStage: window.Configuration.ENVIRONMENT,
    apiKey: window.Configuration.BUGSNAG_API_KEY,
    enabledReleaseStages: [ 'dev', 'qa', 'production' ],
    plugins: [new BugsnagPluginReact(React)]
})

const ErrorBoundary = Bugsnag.getPlugin('react')

const MyCustomToastContainer = props => (
  <DefaultToastContainer {...props} style={{ zIndex: 1000, overflow: 'initial' }} />
);

const MyDiaryToast = (props) => {
  return (
    <div className={`toast toast-${props.appearance}`}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <div className="toast-content">
        {props.children}
      </div>

      <div className="toast-dismiss">
        <button className="btn-close" onClick={props.onDismiss}/>
      </div>
    </div>
  );
};

ReactDOM.render(
<ErrorBoundary>
  <React.StrictMode>
    <ToastProvider
      autoDismissTimeout={6000}
      components={{ ToastContainer: MyCustomToastContainer, Toast: MyDiaryToast }}>
			<Router>
				<App />
			</Router>
    </ToastProvider>
  </React.StrictMode>
</ErrorBoundary>,
  document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register();
