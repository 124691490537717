import React, {useEffect, useState} from 'react';
import auth from "../utils/auth";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import api from "../utils/api";
import {ErrorMessage, Field, Form} from "formik";
import CustomFormik from "../components/Form/CustomFormik";
import { useToasts } from 'react-toast-notifications';

const Sidebar = (props) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [profile, setProfile] = useState([]);
	const [languages, setLanguages] = useState([]);
	const profileUpdateUrl = 'user/profile';

	const { addToast } = useToasts();
	const logout = () => {
		auth.logout()
			.then(res => {
				props.globalState.setUser(null)
				history.push("/login");
			});
	};



	useEffect(() => {
		api.get("user/profile")
			.then(result => {
				if (result.data) {
					setProfile(result.data.data);
				}
		});
	}, []);

	useEffect(() => {
		api.get("languages")
			.then(result => {
				if (result.data) {
					setLanguages(result.data.data);
				}
		});
	}, [props.globalState.locale]);
		

	let attributes = {
		login: '',
		first_name: '',
		last_name: '',
		email: '',
		old_password: '',
		password: '',
		password_confirmation: '',
		language_id: ''
	}

	if (profile.attributes){
		for (let index in attributes){
			if (profile.attributes[index]){
				attributes[index] = profile.attributes[index]
			}
		}
	}
	return (
		<aside id="sidebar">
			<div className="sidebar-inner-wrap">
				<button className="btn-tertiary" onClick={() => logout()}>{t('login.action.logout')}</button>
				{profile.attributes ?
				<CustomFormik
					validateOnChange={false}
					url={profileUpdateUrl}
					method='put'
					initialValues={attributes}
					rules={{
						'login': 'required',
						'first_name': 'required',
						'last_name': 'required',
						'old_password': "required_with:password",
						"password": "different:old_password|confirmed",
						"password_confirmation": "required_with:password"
					}}
					attributeNames={{
						'login': t('login.label.login'),
						'first_name': t('user.label.first-name'),
						'last_name': t('user.label.last-name'),
						'email': t('common.label.email'),
						'old_password': t('login.label.current-password'),
						"password": t('login.label.password'),
						"password_confirmation": t('login.label.confirm-password')
					}}

					clear={['old_password', 'password', 'password_confirmation']}
					onSuccess={(response) => {
						addToast(t('user.message.success-update-profile'), {
							appearance: "success",
							autoDismiss: true
						});
						let user = props.globalState.user
						user.refresh().then((user) => {
							props.globalState.setUser(user)
							props.close()
							
						}).catch(() => auth.logout());		
					
					}}
					onBackgroundSave={(response) => {
						props.close();
					}}
				
				>
				{({ isSubmitting }) => (
					<Form>
						<div className='input-text'>
							<Field type="text" name="first_name" placeholder={t('user.label.first-name')} />
							<div className="input-error-message text-danger">
								<ErrorMessage name="first_name"  />
							</div>
						</div>
						<div className='input-text'>
							<Field type="text" name="last_name" placeholder={t('user.label.last-name')} />
							<div className="input-error-message text-danger">
								<ErrorMessage name="last_name"  />
							</div>
						</div>
						<div className='input-text'>
							<Field type="text" name="login" placeholder={t('login.label.login')} />
							<div className="input-error-message text-danger">
								<ErrorMessage name="login"  />
							</div>
						</div>
						<div className='input-text'>
							<Field type="email" name="email" placeholder={t('common.label.email')} />
							<div className="input-error-message text-danger">
								<ErrorMessage name="email"  />
							</div>
						</div>
						<div className='input-text'>
							<Field type="password" name="old_password" placeholder={t('login.label.current-password')} />
							<div className="input-error-message text-danger">
								<ErrorMessage name="old_password"  />
							</div>
						</div>
						<div className='input-text'>
							<Field type="password" name="password" placeholder={t('login.label.password')} />
							<div className="input-error-message text-danger">
								<ErrorMessage name="password"  />
							</div>
						</div>
						<div className='input-text'>
							<Field type="password" name="password_confirmation" placeholder={t('login.label.confirm-password')} />
							<div className="input-error-message text-danger">
								<ErrorMessage name="password_confirmation"  />
							</div>
						</div>
						<div className='input-text'>
							<Field as="select" name="language_id" placeholder={t('common.label.language')}>
								{languages.map(language => {
									return <option key={language.id} value={language.id}>{language.attributes.name}</option>
								})}
							</Field>
							<div className="input-error-message text-danger">
								<ErrorMessage name="language_id"  />
							</div>
						</div>
						<div className="form-group">
							<button className="btn-primary" type="submit" disabled={isSubmitting} style={{width: "100%"}}>
								{t('user.action.update-profile')}
							</button>
						</div>
					</Form>
				)}
				</CustomFormik>
				: ''}
			</div>
		</aside>
	);
};

export default Sidebar;
