import api from "js/utils/api";
import {apiUrl} from "js/utils/api_url"
import auth_token from "js/utils/auth_token";
import User from "js/Models/User";

export default {
	login: (data) => {
		return new Promise((resolve, reject) => {
			api.post(apiUrl("auth/login"), data)
				.then((response) => {
					auth_token.set(response.data.token);
					localStorage.setItem("user", JSON.stringify(response.data.user));
					document.body.classList.remove('public');
					resolve(response)
				})
				.catch((error) => reject(error))
		});
	},

	logout: () => {
		return new Promise((resolve, reject) => {
			api.post(apiUrl("auth/logout"))
				.finally((response) => {
					auth_token.clear();
					localStorage.clear();
					document.body.classList.remove('menu-open');
					resolve(response)
				})
				.catch((error) => reject(error))
		});
	},

	check: () => {
		return auth_token.get() != null;
	},

	user: () => {
		let userData = localStorage.getItem("user");

		if (!userData){
			return null;
		}

		try {
			let user = new User(
				JSON.parse(userData)
			)

			return user;
		} catch(e) {
			return null;
		}
	}
}
